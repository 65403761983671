import React from 'react'

import './first-time-buyer-offer.scss'

const FirstTimeBuyerOffers = () => {
    return (
        <>
	    <div className='main-content'>
		    <div className='title'> FIRST TIME BUYER OFFER TERMS & CONDITIONS </div>
            <p className='sub-heading'>DEFINITIONS</p>
            <p>For the purpose of these terms and conditions (“Terms and Conditions”):</p>
            <ul style={{"listStyle":"disc"}}>
                <li>
                    <p><b>“User”</b> shall mean a business entity that has On-Boarded on the Solv Platform using its legal name and mobile number and who satisfies the conditions of this Offer to become eligible to receive the discounts and other benefits as set out in this Offer;</p>
                </li>
                <li>
                    <p><b>“FMCG and HoReCa Products”</b>means fast moving consumer goods, groceries, fruits, vegetables and dairy products;</p>
                </li>
                <li>
                    <p><b>“Offer”</b>shall mean the discounts and other benefits offered to the New Users during the Offer Period subject to the terms and conditions as set out herein;</p>
                </li>
                <li>
                    <p><b>“Offer Period”</b>shall mean the period commencing from 01st August 2021 till 30 September 2022 (both days inclusive), which may be amended by Solv at any time;</p>
                </li>
                <li>
                    <p><b>“Verify” or “Verification” </b>(along with its grammatical variations) in relation to a User means the downloading and installation of the Solv application by the User, providing relevant Merchant Information to Solv, successful verification of Merchant Information and registration of User as a seller/buyer on the Solv Platform by obtaining a unique Solv identification number;</p>
                </li>
                <li>
                    <p><b>“Primary Terms and Conditions”</b>shall mean the terms and conditions governing the usage of the Solv platform, as applicable to the Users as separately provided on the Solv portal and/or application in addition to these Terms and Conditions set out herein.</p>
                </li>
            </ul>
            <p>Any capitalised terms used in these Terms and Conditions, however not defined herein, shall have the same meanings as attributed to such terms in the Primary Terms and Conditions.</p>
            <p className='sub-heading'>OFFERS</p>
            <p>
            All Users Verified as buyers onto the Solv platform (including those Verified Users who are newly Verified during the Offer Period) who have not previously undertaken any transaction on the Platform (“New Users”), are eligible to use discount coupon code as detailed below based on the category of purchase in the form of promotional codes. Such promotional code can be redeemed by New Users towards purchasing products from sellers on the Platform based on the minimum invoice value as defined for each promotional code during the Offer Period.
            </p>
            <p className='sub-heading'> PROMOTIONAL CODES AND APPLICABLE CONDITIONS</p>

            <p className='sub-heading'>A. FMCG and HoReCa Products</p>
            <ul style={{"listStyleType":"upper-roman"}}>
                        <li>
                            <p>
                            	All New Users shall receive a discount coupon code of INR 150 for completing their first purchase transaction of a minimum transaction value of Rs. 1000 and above, which coupon will be valid for a period of 48 hours from the time of successful On-Boarding. Use Code: <b>SOLVFH24</b>
                            </p>
                        </li>
                        <li>
                            <p>
                            	All New Users shall receive a discount coupon code of INR 100 for completing their first purchase transaction of a minimum transaction value of Rs. 1000 and above. Use Code: <b>SOLVNEW</b>
                            </p>
                        </li>
                        <li>
                            <p>
                                Upon completing the first transaction, the New Users shall receive a discount coupon code of INR 120 for  a minimum transaction value of Rs. 1500 and above. This coupon shall be valid for a period of 10 days from the date of its issuance. Use Code: <b>SOLVNEW120</b>
                            </p>
                        </li>
                        <li>
                            <p>
                                Upon completing the second transaction, the New Users shall receive a discount coupon code of INR 101 for a minimum transaction value of Rs. 2000 and above. This coupon shall be valid for a period of 7 days from the date of its issuance. Use Code:  <b>SOLVNEW101</b>
                            </p>
                        </li>
            </ul>

            <p className='sub-heading'>B. Consumer Electronics</p>
            <ul style={{"listStyleType":"upper-roman"}}>
                        <li>
                            <p>
                                All New Users shall receive a discount coupon code of INR 100 for completing their first purchase transaction of a minimum transaction value of Rs. 1000 and above. Use Code: <b>STARTCE100</b>
                            </p>
                        </li>
                        <li>
                            <p>
                                All New Users shall receive a discount coupon code of INR 250 for completing their first purchase transaction of a minimum transaction value of Rs. 3000 and above. Use Code: <b>STARTSOLV</b>
                            </p>
                        </li>
                        <li>
                            <p>
                                Upon completing the first transaction, the New Users shall receive a discount coupon code of INR 320 for a minimum transaction value of Rs. 4000 and above. This coupon shall be valid for a period of 10 days from the date of its issuance. Use Code: <b>STARTSOLV320</b>
                            </p>
                        </li>
                        <li>
                            <p>
                                Upon completing the second transaction, the New Users shall receive a discount coupon code of INR 250 for a minimum transaction value of Rs. 5000 and above. This coupon shall be valid for a period of 7 days from the date of its issuance. Use Code:  <b>STARTSOLV250</b>
                            </p>
                        </li>
            </ul>

            <p className='sub-heading'>C. Apparel and Fashion, Home Furnishing and Footwear Products </p>
            <ul style={{"listStyleType":"upper-roman"}}>
                        {/* <li>
                            <p>
                                All New Users shall receive a discount coupon code of INR 350 for completing their first purchase transaction of a minimum transaction value of Rs. 3000 and above, which coupon will be valid for a period of 48 hours from the time of successful On-Boarding. Use Code: <b>SOLV48</b>
                            </p>
                        </li> */}
                        <li>
                            <p>
                                All New Users shall receive a discount coupon code of INR 250 for completing their first purchase transaction of a minimum transaction value of Rs. 3000 and above. Use Code: <b>STARTSOLV</b>
                            </p>
                        </li>
                        <li>
                            <p>
                                Upon completing the first transaction, the New Users shall receive a discount coupon code of INR 320 for a minimum transaction value of Rs. 4000 and above. This coupon shall be valid for a period of 10 days from the date of its issuance. Use Code: <b>STARTSOLV320</b>
                            </p>
                        </li>
                        <li>
                            <p>
                                Upon completing the second transaction, the New Users shall receive a discount coupon code of INR 250 for a minimum transaction value of Rs. 5000 and above. This coupon shall be valid for a period of 7 days from the date of its issuance. Use Code: <b>STARTSOLV250</b>
                            </p>
                        </li>
            </ul>
                
            <p className='sub-heading'>CONDITIONS:</p>
            <ul style={{"listStyleType":"lower-alpha"}}>
                <li>
                    <p> 
                        The Offer is open to the Users On-Boarded as buyers in the product categories of (i) FMCG and HoReCa; (ii) Apparel and Fashion; (iii) Consumer Electronics; (iv) Home Furnishing; and (v) Footwear; as per the serviceable pin codes mentioned below. 
                    </p>
                </li>
                <li>
                    <p>
                    	Solv shall only process orders for products in serviceable pin codes listed out in the <a className='mail-anchor' href="#annexture-section"><b style={{"textDecoration":"underline"}}> Annexure below</b></a>. However it is clarified that the attached list of serviceable pin codes is tentative and subject to change from time to time without prior notice, depending on notification of containment reasons on account of Covid-19, lockdown and other restrictions imposed by governmental authorities, operational challenges, etc. The Users must seek the assistance of relevant Solv sales officers to ascertain the up to date list of serviceable pin codes.
                    </p>
                </li>
                <li>
                    <p>
                    	In the event the promotional coupon code is not immediately displayed for any newly On-Boarded User, the new Users are encouraged to contact our sales officers/help desk for assistance in applying the promotional codes.
                    </p>
                </li>
                <li>
                    <p>
                    	If the User places a purchase order on the Platform, however the relevant seller does not accept such purchase order or the order is not processed, the User’s discount voucher shall not be considered as consumed. Further, if the User places an order for certain quantity of products on the Platform exceeding the threshold amount of invoice values mentioned above, however the order is only partially fulfilled by the seller as a result of which the invoice value falls below the threshold amounts, any redemption of discount voucher by the User shall be at the discretion and in the manner determined by Solv.
                    </p>
                </li>
                <li>
                    <p>
                    	During the Offer Period, payment for orders by way of advance/pre-paid mode shall not be available on the Platform for availing the Offers. 
                    </p>
                </li>
                <li>
                    <p>
                    	The New Users shall not be entitled to place reverse pick-up orders during the Offer Period. In case any New User orders for products and avails the discount, such New User shall not be entitled to return the goods. In the case the New User wants reverse pick-up services, the discount availed by the User shall be forfeited and such amount will be adjusted in the manner determined by Solv. 
                    </p>
                </li>
                <li>
                    <p>
                    	Any discount voucher once applied by New Users for placing orders on the Platform (including in case of cancelled/RTO orders) shall be considered as consumed, unless otherwise stated in these Terms and Conditions specifically. 
                    </p>
                </li>
                <li>
                    <p>
                    	In the event any New User is eligible for more than one Offer, such User shall be entitled to choose the Offer which the User wants to avail, subject to the following conditions:
                    </p>
                    <ol style={{"listStyleType":"upper-roman"}}>
                        <li>
                            <p>
                            	No two Offers can be clubbed for a single order;   
                            </p>
                        </li>
                        <li>
                            <p>
                            	The relevant seller must receive the full invoice value from the New User;
                            </p>
                        </li>
                        <li>
                            <p>
                                In the event a User is also eligible to receive any other promotional offer towards completing his first purchase on the Platform, the User shall be entitled to choose the offer which he intends to avail and the unutilized first time buyer offer will not be available for any subsequent order. However, it is clarified that in the event the User did not avail the current Offer within the 48 hour time period after On-Boarding, such User will continue to be eligible for availing any other first time buyer Offer which may be available to him.
                            </p>
                        </li>
                        <li>
                            <p>
                            	In case of COD payments, the New User shall make payment of the invoice amount net of the applicable discount amount.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                    	The thresholds of minimum invoice amounts which are required to be fulfilled by New Users for availing the Offers as set out in these Terms and Conditions shall be such amounts net off all applicable taxes.
                    </p>
                </li>
                <li>
                    <p>
                   	    The participation in the Offer is entirely voluntary and it is understood that the participation by the Users shall be deemed to have been made on a voluntary basis.
                    </p>
                </li>
                <li>
                    <p>
                    	In all matters relating to the Offer, including the application of discount, the decisions of Solv shall be final and binding. 
                    </p>
                </li>
                <li>
                    <p>
                    l.	Any person availing this Offer shall be deemed to have accepted these Terms. The Users shall continue to be bound to comply with the Primary Terms and Conditions and these Terms shall be in addition to and not in substitution /derogation to the Primary Terms and Conditions governing the usage of Solv services.
                    </p>
                </li>
                <li>
                    <p>
                    	Solv reserves the right to modify at any time, all or any of the terms applicable to the Offer, including the terms contained herein, without assigning any reasons or without any prior intimation whatsoever. Solv also reserves the right to discontinue or terminate the Offer without assigning any reasons or without any prior intimation whatsoever at any time during the Offer Period. 
                    </p>
                </li>
                <li>
                    <p>
                    	The New Users shall not be entitled to assign the rights and benefits arising under this Offer to any other person. No requests for transfer or assignment of the benefits under the Offer shall be entertained.
                    </p>
                </li>
                <li>
                    <p>
                   	By participating in this Offer, the User expressly agrees that Solv or any of its affiliates will not be liable or responsible for any loss or damage whatsoever that you may suffer, directly or indirectly, in connection with this Offer, including but not limited to that associated with his/her use or delivery or misuse of any promotional codes and/or benefit provided hereunder. In the event Solv suffers any loss or damage arising out of or resulting from any inaccurate information provided by the User or breach of any applicable law or terms and conditions, the User shall be liable to indemnify Solv for all the loss and damage incurred.
                    </p>
                </li>
                <li>
                    <p>
                   	Under no circumstances will the benefit being offered under this Offer be provided in cash by Solv, i.e. there are no cash or other alternatives available in whole or in part, in relation to the benefits under this Offer.
                    </p>
                </li>
                <li>
                    <p>
                    	Solv reserves the right to disqualify any merchant establishment or User from the benefits of the Offer and initiate appropriate legal action, if any fraudulent activity is identified as being carried out for the purpose of availing the benefits under the Offer. Further, Solv reserves the right to deny honouring the Offer or any benefit thereof on the grounds of suspicion or abuse of the Offer by any User without providing the User any explanation thereof.
                    </p>
                </li>
                <li>
                    <p>
                    	Any queries or disputes from Users regarding issuance/denial of discount, can only be raised within 30 days from the date of expiry of Offer Period. 
                    </p>
                </li>
                <li>
                    <p>
                    	All communication and notices about this Offer should be addressed to  &nbsp;
                        <a className='mail-anchor' href="mailto:cs@solvezy.com">cs@solvezy.com</a>.
                    </p>
                </li>
                <li>
                    <p>
                    	All disputes are subject to the exclusive jurisdiction of the competent courts/tribunals of Bangalore.
                    </p>
                </li>
            </ul>
            <p className='sub-heading text-center' id="annexture-section">ANNEXURE-1</p>
            <p className='sub-heading text-center'>SERVICEABLE PIN CODES</p>
            <p>
                <a className='mail-anchor' href={`/Apparel serviceable Pincodes.xls`} download>Pin codes serviceable for Apparel and Fashion Products</a>
            </p>
            <p>
                <a className='mail-anchor' href={`/FMCG-&-Horeca-serviceable-pincodes-March-Offer.xlsx`} download>Pin codes serviceable for FMCG & HoReCa Products</a>
            </p>
            <p>
                <a className='mail-anchor' href={`/Consumer Electronics serviceable Pincodes.xlsx`} download>Pin Codes serviceable for Consumer Electronics Products</a>
            </p>
            <p>
                <a className='mail-anchor' href={`/Home Furnishings serviceable Pincodes.xlsx`} download>Pin Codes serviceable for Home Furnishing Products</a>
            </p>

            <p>
                <a className='mail-anchor' href={`/Footwear-serviceable-Pincodes-March-Offer.xlsx`} download>Pin Codes serviceable for Footwear Products</a>
            </p>
            
		</div>
	</>
    )
}
export default FirstTimeBuyerOffers
