import React from 'react'

import styles from "./../../../policies-css-module.module.scss"
import '../../../../styles.scss'

import { Layout } from '../../../../components'

// import FirstTimeBuyPolicy from '../../../../components/policies/firstTimeBuyPolicy/firstTimeBuyPolicy'
import  FirstTimeBuyerOffers  from '../../../../components/policies/firstTimeBuyerOffer/first-time-buyer-offer'; 
import { Link } from "gatsby"

const FirstTimeBuyOffer = () => {
	return (
		<>
			<Layout>
					<div className={styles.container}>
						<div className={styles.link_section}>
							<div className={styles.link_section_div}>
								<Link exact className={styles.tc_links} activeClassName='is-active' to='/promos/festiveoffer/tnc/'>FIRST TIME BUYER OFFER</Link>
							</div>
						</div>
						<div className={styles.content_section}>
								{/* <FirstTimeBuyPolicy /> */}
								<FirstTimeBuyerOffers />
						</div>
					</div>
			</Layout>
		</>
	)
}

export default FirstTimeBuyOffer
